import { css } from '@emotion/react'
import type { ActivableProductInformationId } from '@orus.eu/dimensions'
import { ActivableProductInformationIdBadge, spacing } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
type ActivableProductInformationIdsBadgesListProps = {
  activableProductInformationIds: ActivableProductInformationId[]
}

export function ActivableProductInformationIdsBadgesList(
  props: ActivableProductInformationIdsBadgesListProps,
): ReactElement {
  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing[30]};
      `}
    >
      {props.activableProductInformationIds.map((activableProductInformationId, index) => (
        <ActivableProductInformationIdBadge key={index} activableProductInformationId={activableProductInformationId} />
      ))}
    </div>
  )
}
